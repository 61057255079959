import React from "react";
import { useSelector } from "react-redux";

const ScriptStudy = ({ handleAnswerPress, handleAnswerRelease, handleTick, handleRetry, toggleTranslation, translationError, isLoading }) => {
    const responses = useSelector((state) => state.responses);
    const showTranslation = useSelector((state) => state.showTranslation);
    const loadingTranslation = useSelector((state) => state.loadingTranslation);
    const transcript = useSelector((state) => state.scriptTranscript);
    const translation = useSelector((state) => state.translation);
    const showTick = useSelector((state) => state.showTick);
    const fetchingResponses = useSelector((state) => state.fetchingResponses);

    function getLastUserMessageIndex(conversation) {
        for (let i = conversation.length - 1; i >= 0; i--) {
            if (conversation[i].role === 'user') {
                return i;
            }
        }
        return -1;
    }   
    

    const responseKeys = Object.keys(responses)
    return(
        <div className="">
            <div className="col-sm-1"/>
            <div className="row border col-sm-10 mx-auto" id="chatbox">
                <div className="messages col-12 p-0">
                    {!showTranslation ? 
                        transcript.map((message, index) => (
                            <div key={index}>
                                {message.role === 'user' && isLoading && index === getLastUserMessageIndex(transcript) ?
                                    <>
                                        <p className="text-end px-2">{message.content}</p>
                                        <div className="loader mb-3 px-2">
                                            <span className="loader__element"></span>
                                            <span className="loader__element"></span>
                                            <span className="loader__element"></span>
                                        </div>
                                    </>
                                :
                                    message.role === 'user' ?
                                        <p className="text-end px-2" id={index}>
                                            {index === getLastUserMessageIndex(transcript) && showTick &&
                                                <>
                                                    <span className="fa-solid fa-check me-2 hover-zoom" onClick={handleTick} onTouchEnd={handleTick}></span>
                                                    <span className="fa-solid fa-rotate-right me-3 hover-zoom" onClick={handleRetry} onTouchEnd={handleRetry}></span>
                                                </>
                                            }
                                            {message.content}
                                        </p>
                                    :
                                        <p className="px-2">{message.content}</p>                                   
                                }
                            </div>
                        )) 
                    :
                        !translationError ?
                            translation.map((message, index) => (
                                <div key={index}>
                                    {message.role === 'assistant'
                                        ? 
                                        index === transcript.length - 1 && isLoading ?
                                            <div className="loader mb-3">
                                                <span className="loader__element"></span>
                                                <span className="loader__element"></span>
                                                <span className="loader__element"></span>
                                            </div>
                                            :
                                            <p className="px-2">{message.content}</p>
                                        : <p className="text-end px-2">{message.content}</p>
                                    }
                                </div>
                            ))
                        :
                            <p className="px-2 text-center text-danger">{translationError}</p>
                    }
                    <button className="border border-dark w-100 m-0 bg-dark text-white row py-1 translate-btn" onClick={toggleTranslation}>
                        {loadingTranslation?
                        <div className="translate-loader d-flex justify-content-center">
                            <span className="loader__element"></span>
                            <span className="loader__element"></span>
                            <span className="loader__element"></span>
                        </div>
                        :
                        <span>Translate Conversation</span>
                        }
                    </button>
                </div>
            </div>
            <div className="col-sm-1"/>
            <div className="interact row m-0 d-flex justify-content-center mt-3">
                {fetchingResponses ?
                <div className="responses-loader d-flex justify-content-center">
                    <span className="loader__element"></span>
                    <span className="loader__element"></span>
                    <span className="loader__element"></span>
                </div>
                :    
                responseKeys.map((response, index) => (
                    <button className="btn btn-danger border rounded col-sm-10" key={index} onMouseDown={() => handleAnswerPress(responses[response])} onMouseUp={handleAnswerRelease}
                    onTouchStart={() => handleAnswerPress(responses[response])} onTouchEnd={handleAnswerRelease}>{responses[response]}</button>
                ))
                }
            </div>
        </div>
    )
};

export default ScriptStudy;