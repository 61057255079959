import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import FreeStudy from "../components/FreeStudy";
import axios from 'axios';
import getJwtTokenFromCookie from "../helpers/getJwtToken";
import { useDispatch, useSelector } from "react-redux";
import { setIsListening,
    setShowTranslation,
    setFetching,
    setTranscriptUpdated,
    setResponseUpdated,
    setLoadingTranslation,
    setShowTick,
    setConversation,
    appendToConversation,
    setTranslation,
    setPreviousConversation,
    setLanguages
} from "../reducers/reducers";

const langCodes = {'french': 'fr', 'italian': 'it', 'japanese': 'ja', 'mandarin': 'zh', 'spanish': 'es'};

const FreeStudyContainer = () => {
    const showTranslation = useSelector((state) => state.showTranslation); // Used to display translated text
    const fetching = useSelector((state) => state.fetching); // Used to identify whether requests are being made
    const transcriptUpdated = useSelector((state) => state.transcriptUpdated); // Used to identify whether the transcript has been updated (total conversation)
    const responseUpdated = useSelector((state) => state.responseUpdated); // Used to indicate whether the bot has responded
    const conversation = useSelector((state) => state.conversation); // Store the conversation
    const previousConversation = useSelector((state) => state.previousConversation); // Store the conversation prior to the last message
    const languages = useSelector((state) => state.languages.languages); // Get the user's languages
    
    const [recording, setRecording] = useState(false); // Store the user's last recording
    const [translationError, setTranslationError] = useState(''); // Hold any translation error messages
    const [isLoading, setIsLoading] = useState(false) // Hold the state of loading the bot response
    const [languagesFetched, setLanguagesFetched] = useState(false); // Hold the state of whether the user's languages have been fetched
    const [hasResponded, setHasResponded] = useState(false) // Hold the state of loading the bot response

    const transcriptRef = useRef("");
    const language = useParams().language; // The language the user is practicing
    const jwtToken = getJwtTokenFromCookie(); // JWT
    const navigate = useNavigate(); 
    const dispatch = useDispatch();



    // Assuming a backend response isn't being fetched, allow the user to speak
    const handleMicPress = async () => {
        if ('speechSynthesis' in window ) {
            speechSynthesis.cancel(); // removes anything 'stuck'
            speechSynthesis.getVoices();
            // Safari loads voices synchronously so now safe to enable

            setHasResponded(false);
            if (!fetching) { 
                dispatch(setShowTranslation(false)); // Hide the translated text
                dispatch(setIsListening(true)); // Update listening state to true
                try {
                    dispatch(setFetching(true)); // Update fetching state
                    const recognition = new window.webkitSpeechRecognition(); // Create speech recognition object
                    recognition.lang = langCodes[language]; // Set the recognition code to the user's language
                    recognition.continuous = true; // Keep recognising speech until stopped
                    recognition.interimResults = true; // Return interim results
                    recognition.onresult = event => {
                        const interimTranscript = Array.from(event.results)
                            .map(result => result[0].transcript)
                            .join("");
                        transcriptRef.current = interimTranscript; // Update ref with interim transcript
                        setHasResponded(true);
                    };
                    recognition.start(); // Begin the voice recognition
                    setRecording(recognition); // Update the recording state to hold the user's speech
                } catch (error) {
                    alert('You need to grant the browser microphone access!'); 
                    console.log(error)
                }
            }
        }
    };

    // Deal with the user finishing speaking
    const handleMicRelease = () => {
        dispatch(setIsListening(false)); // Turn off listening state
        if (recording) { 
            recording.stop(); //Stop listening to user
        }
    };

    // TTS the message
    const speakMessage = (response) => {
        var msg = new SpeechSynthesisUtterance(); // Create a Speech Synthesis instance
        msg.lang = langCodes[language] // Set language
        msg.text = (response); // Assign the input as the text value of msg
        window.speechSynthesis.speak(msg); // Speak the message
    }

    // // Handle user pressing the retry button
    const handleRetry = () => {
        console.log(conversation[conversation.length - 1])
        var updatedTranscript;
        if (conversation[conversation.length - 1].role === 'assistant') {
            updatedTranscript = conversation.slice(0, conversation.length-2);
        } else {
            updatedTranscript = conversation.slice(0, conversation.length-1);
        }
        dispatch(setConversation(updatedTranscript)); // Remove the last message from the conversation
        dispatch(setFetching(false)); // Set fetching to false
        dispatch(setShowTick(false)); // Turn off showing tick
    }

    // Set the user's languages if they haven't already been set
    useEffect(() => {
        if (!languagesFetched) { // If the languages haven't been fetched
            const fetchData = async () => {
                try {
                    const jwtToken = getJwtTokenFromCookie(); // Assign JWT
                    const response = await axios.get('https://linguallect.onrender.com/languages', { // Fetch languages
                        headers: {
                            Authorization: `Bearer ${jwtToken}`
                        }
                    });
                    const languages = response.data.map(language => ({
                        name: language.charAt(0).toUpperCase() + language.slice(1),
                        value: language
                    }));
                    dispatch(setLanguages(languages)); // Update languages state
                    setLanguagesFetched(true); // Set the flag to true after languages are fetched
                } catch (error) {
                    console.log(error);
                }
            };
    
            fetchData();
        }
    }, [languagesFetched]);
    
    // Either allow the user to access the page or navigate them to the home page
    useEffect(() => {
        const languageOwned = languages.some(lang => lang.value === language);
        if (!languageOwned && languagesFetched) { // Check if languagesFetched is true and if the user doesn't own the language
            navigate('/'); // Navigate them to home
        }
    }, [languages, languagesFetched]);

    // Handle final transcript update when recording stops
    useEffect(() => {
        if (recording) { 
            recording.onend = () => { // When the recording stops
                var finalTranscript = '';
                if (hasResponded) {
                    finalTranscript = transcriptRef.current; // Use the current (latest) transcript
                } else {
                    finalTranscript = ''; // Use the current (latest) transcript
                }
                dispatch(appendToConversation([{ role: 'user', content: finalTranscript }])); // Append the user's new dialogue to the conversastion
                dispatch(setTranscriptUpdated(true)); // Transcript is now updated
            };
        }
    }, [recording, hasResponded]); // Fire whenever recording state changes

    // Make backend calls when transcript is updated
    useEffect(() => {
        if (conversation.length >= 1) { // Don't fire on page load
            if (transcriptUpdated) {
                sendTranscriptToBackend(conversation); // Call sendTranscriptToBackend with the current transcript
                dispatch(setFetching(false)); // The backend response has now been fetched
                dispatch(setTranscriptUpdated(false)); // Transcript is no longer updated from bot's last message
            }
        }
    }, [transcriptUpdated]); // Fire whenever transcript is updated

    // Speak the latest bot message
    useEffect(() => {
        if (conversation.length >= 1) {
            const lastMessage = conversation[conversation.length-1].content; // Get the final message from the conversation
            if (responseUpdated) { // If the bot has responded
                dispatch(setResponseUpdated(false));
                speakMessage(lastMessage);
            }
        }
    }, [responseUpdated]) // Fire whenever response (bot) is updated

    // Send the conversation to the backend containing the user's latest message
    const sendTranscriptToBackend = async (conversation) => {
        if (hasResponded) {
            setIsLoading(true); // Set the loading state to true as we wait for a response
            const aiTranscript = [
                {role: 'system', content: `You are my friend who only speaks ${language}. You do not comprehend any other languages at all.`},
                ...conversation
            ]; // Provide the bot context
            try {
                const response = await axios.post(`https://linguallect.onrender.com/chat/${language}`, {aiTranscript}, {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                });
                dispatch(appendToConversation([{role: 'assistant', content: response.data}])); // Add the bot response to the conversation
                dispatch(setResponseUpdated(true)); // Response has been updated
            } catch(err) {
                console.error('Error sending transcript to backend: ', err)
            } finally {
                setIsLoading(false);
            }
        }
    }  

    // Toggle the display of the translated conversation
    const toggleTranslation = async() => {
        if (conversation.length > 0) { // Prevent firing on an empty conversation
            const currentConversation = JSON.stringify(conversation); // Convert conversation to a string
            if (currentConversation === previousConversation) { // Check if the current conversation is the same as the previous one
                dispatch(setShowTranslation(!showTranslation)) // Toggle the state of showTranslation
                return; // Exit as to avoid retranslating an already translated conversation
            }
    
            dispatch(setPreviousConversation(currentConversation)); // Update the previousConversation state
            if (!showTranslation) {
                try {
                    setTranslationError(''); // Get rid of any previous error messages
                    dispatch(setFetching(true)); // Set the fetching state to true
                    dispatch(setLoadingTranslation(true)); // Set the LoadingTranslation state to true to show animation
                    const response = await axios.post(`https://linguallect.onrender.com/translate`, {conversation}, {
                        headers: {
                            Authorization: `Bearer ${jwtToken}`
                        }
                    });
                    const translationsArray = Object.values(response.data);
                    if (!(translationsArray[0] === 'Formatting error')) { // As long as the bot returns a valid format response
                        dispatch(setTranslation(translationsArray)); // Update the translation
                    } else { // Otherwise
                        setTranslationError('Unable to retrieve translation at this time.'); // Update the error message
                    }
                } catch (err) {
                    console.log(err)
                    setTranslationError('Unable to retrieve translation.');
                }
                dispatch(setLoadingTranslation(false)); // Update to reflect that the translation has been completed
                dispatch(setFetching(false)); // No longer fetching
            }
            dispatch(setShowTranslation(!showTranslation)); // Toggle the state of displaying the translated text
        }
    }

    return (
        <FreeStudy
            handleMicPress={handleMicPress}
            handleMicRelease={handleMicRelease}
            language={language}
            toggleTranslation={toggleTranslation}
            translationError={translationError}
            isLoading={isLoading}
            handleRetry={handleRetry}
        />
    );
}

export default FreeStudyContainer;