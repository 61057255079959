import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setUser } from '../reducers/reducers';
import Axios from "axios";
import getJwtTokenFromCookie from "../helpers/getJwtToken";

const Verify = () => {
    const navigate = useNavigate();
    const { token } = useParams();
    const [isVerified, setIsVerified] = useState(false);
    const dispatch = useDispatch();
    const jwtToken = getJwtTokenFromCookie();

    useEffect(() => {
        const verifyToken = async () => {
            try {
                await Axios.get(`https://linguallect.onrender.com/verify/${token}`, {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                });
                setIsVerified(true);
                dispatch(setUser({
                    isVerified: true
                }));
            } catch (err) {
                console.log(err)
            }
        };

        verifyToken();
    }, [token]);

    const handleButtonClick = () => {
        navigate('/');
    }

    return (
        <div className="w-100 mx-0 text-center">
            {isVerified ? (
                <div className="mt-5">
                    <h1>Email Verified Successfully!</h1>
                </div>
            ) : (
                <div className="mt-5">
                    <h1>Failed to Verify Email</h1>
                    <p>Please check the verification link or contact support.</p>
                </div>
            )}
            <button className="btn btn-danger" onClick={handleButtonClick}>Return to home</button>
        </div>
    );
}

export default Verify;
