import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ScriptStudy from "../components/ScriptStudy";
import getJwtTokenFromCookie from "../helpers/getJwtToken";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setIsListening,
    setResponses,
    setChosenResponse,
    setFetching,
    setTranscriptUpdated,
    setResponseUpdated,
    setShowTick,
    setBotResponse,
    setLoadingTranslation,
    setPreviousConversation,
    setShowTranslation,
    setTranslation,
    setFetchingResponses,
    appendToScriptTranscript,
    appendToConversation,
    setScriptTranscript,
    setConversation,
    setLanguages
} from "../reducers/reducers";

const langCodes = {'french': 'fr', 'italian': 'it', 'japanese': 'ja', 'mandarin': 'zh-CN', 'spanish': 'es'};
const introPhrases = {
    'french': 'Bonjour, de quoi veux-tu parler ?',
    'italian': 'Ciao, di cosa vuoi parlare?',
    'japanese': 'こんにちは、何を話したいですか？',
    'mandarin': '你好，你想聊什么？',
    'spanish': 'Hola, ¿de qué quieres hablar?',
}

const ScriptStudyContainer = () => {
    const chosenResponse = useSelector((state) => state.chosenResponse);
    const fetching = useSelector((state) => state.fetching); // Used to identify whether requests are being made
    const transcriptUpdated = useSelector((state) => state.transcriptUpdated); // Used to identify whether the transcript has been updated (total conversation)
    const responseUpdated = useSelector((state) => state.responseUpdated); // Used to indicate whether the bot has responded
    const botResponse = useSelector((state) => state.botResponse); // Used to indicate whether the bot has responded
    const previousConversation = useSelector((state) => state.previousConversation); // Store the conversation prior to the last message
    const showTranslation = useSelector((state) => state.showTranslation); // Used to display translated text
    const transcript = useSelector((state) => state.scriptTranscript); // Store the entire spoken dialogue
    const conversation = useSelector((state) => state.conversation); // Store only the dialogue that progresses the conversation
    const languages = useSelector((state) => state.languages.languages); // Get the user's languages

    const [recording, setRecording] = useState(false); // Store the user's last recording
    const [translationError, setTranslationError] = useState(''); // Store any error messages related to translating the text
    const [isLoading, setIsLoading] = useState(false) // Hold the state of loading the bot response
    const [hasResponded, setHasResponded] = useState(false) // Hold the state of loading the bot response
    const [languagesFetched, setLanguagesFetched] = useState(false); // Hold the state of whether the user's languages have been fetched

    const transcriptRef = useRef("");
    const language = useParams().language; // The language the user is practicing
    const jwtToken = getJwtTokenFromCookie(); // JWT
    const firstMessage = introPhrases[language]; // Bot's first message
    const navigate = useNavigate();
    const dispatch = useDispatch();
       
    // Assuming a backend response isn't being fetched, allow the user to speak
    const handleAnswerPress = async (chosen) => {
        setHasResponded(false);
        if (!fetching) {
            dispatch(setChosenResponse(chosen)); // Update the chosen response with the response the user selected
            dispatch(setShowTranslation(false)); // Hide the translated text
            dispatch(setIsListening(true)); // Update listening state to true
            try {
                dispatch(setFetching(true)); // Update fetching state
                const recognition = new window.webkitSpeechRecognition(); // Create speech recognition object
                recognition.lang = langCodes[language]; // Set the recognition code to the user's language
                recognition.continuous = true; // Keep recognising speech until stopped
                recognition.interimResults = true; // Return interim results
                recognition.onresult = event => {
                    const interimTranscript = Array.from(event.results)
                        .map(result => result[0].transcript)
                        .join("");
                    transcriptRef.current = interimTranscript; // Update ref with interim transcript
                    setHasResponded(true);
                };
                recognition.start(); // Begin the voice recognition
                setRecording(recognition); // Update the recording state to hold the user's speech
            } catch (error) {
                console.error('Error accessing microphone:', error);
            }
        }
    };

    // Deal with the user finishing speaking
    const handleAnswerRelease = () => {
        dispatch(setIsListening(false)); // Turn off listening state
        if (recording) {
            recording.stop(); // Stop listening to user
        }
    };

    // TTS the message
    const speakMessage = (response) => {
        var msg = new SpeechSynthesisUtterance(); // Create a Speech Synthesis instance
        msg.lang = langCodes[language] // Set language
        msg.text = (response); // Assign the input as the text value of msg
        window.speechSynthesis.speak(msg); // Speak the message
    }

    function formatAnswer(str) {
        const regex = /[\p{P}\p{S}\p{C}]+/gu; // Match any non-alphanumeric characters (including punctuation)
        const withoutPunctuation = str.replace(regex, ''); // Remove all punctuation characters from the string using the regular expression
        return withoutPunctuation.toLowerCase(); // Convert the string to lowercase
    }

    const removeDiacritics = str => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // Normalize and remove diacritics from both answer and actual strings
    };

    const compareAnswers = (answer, actual) => {
        const normalizedAnswer = removeDiacritics(answer.normalize("NFD"));
        const normalizedActual = removeDiacritics(actual.normalize("NFD"));
    
        return normalizedAnswer.toLowerCase() === normalizedActual.toLowerCase(); // Compare the transformed strings
    };

    // Set the user's languages if they haven't already been set
    useEffect(() => {
        if (!languagesFetched) { // If the languages haven't been fetched
            const fetchData = async () => {
                try {
                    const jwtToken = getJwtTokenFromCookie(); // Assign JWT
                    const response = await axios.get('http://localhost:5000/languages', { // Fetch languages
                        headers: {
                            Authorization: `Bearer ${jwtToken}`
                        }
                    });
                    const languages = response.data.map(language => ({
                        name: language.charAt(0).toUpperCase() + language.slice(1),
                        value: language
                    }));
                    dispatch(setLanguages(languages)); // Update languages state
                    setLanguagesFetched(true); // Set the flag to true after languages are fetched
                } catch (error) {
                    console.log(error);
                }
            };
    
            fetchData();
        }
    }, [languagesFetched]);
    
    // Either allow the user to access the page or navigate them to the home page
    useEffect(() => {
        const languageOwned = languages.some(lang => lang.value === language);
        if (!languageOwned && languagesFetched) { // Check if languagesFetched is true and if the user doesn't own the language
            navigate('/'); // Navigate them to home
        } else if (languageOwned && transcript.length === 0) {
            dispatch(appendToScriptTranscript([{role: 'assistant', content: firstMessage}])) // Append the bot's new dialogue to the transcript
            dispatch(appendToConversation([{role: 'assistant', content: firstMessage}])) // Append the bot's new dialogue to the conversastion
        }
    }, [languages, languagesFetched]);

    // Generate responses for the user when the bot has responded
    useEffect(() => {
        if (conversation.length === 0) {
            speakMessage(firstMessage) // Speak the messagee
            getResponsesFromBackend(); // Get responses for the user
        } else {
            getResponsesFromBackend(); // Get responses for the user
        }
    }, [botResponse]);

    // Adjust transcript and conversation
    useEffect(() => {
        if (recording) {
            recording.onend = () => { // When the recording stops
                var finalTranscript = '';
                if (hasResponded) {
                    finalTranscript = transcriptRef.current; // Use the current (latest) transcript
                } else {
                    finalTranscript = ''; // Use the current (latest) transcript
                }
                dispatch(appendToScriptTranscript([{role: 'user', content: finalTranscript}])); // Append the user's new dialogue to the transcript
                if (compareAnswers(formatAnswer(finalTranscript), formatAnswer(chosenResponse))) { // If the user's response equals the response they selected
                    dispatch(appendToConversation([{role: 'user', content: finalTranscript}])); // Append the user's new dialogue to the conversation
                    dispatch(setTranscriptUpdated(true)); // Set the transcript updated state to true
                } else {
                    dispatch(setShowTick(true)); // Display the show tick div
                }
            };
        }
    }, [recording, chosenResponse, hasResponded]);     

    // Update the loading state and get the bot response when the transcript is updated
    useEffect(() => {
        const fetchData = async () => {
            if (conversation.length >= 1) { // Don't fire on page load
                if (transcriptUpdated) {
                    sendTranscriptToBackend(conversation); // Call sendTranscriptToBackend with the current transcript
                    dispatch(setFetching(false)); // The backend response has now been fetched
                    dispatch(setTranscriptUpdated(false)); // Transcript is no longer updated from bot's last message
                }
            }
        };

        fetchData(); // Call the async function
    }, [transcriptUpdated]);
    
    // Speak the last message when response is updated
    useEffect(() => {
        if (conversation.length >= 1) {
            const lastMessage = conversation[conversation.length-1].content; // Get the final message from the conversation
            if (responseUpdated) { // If the bot has responded
                dispatch(setResponseUpdated(false));
                speakMessage(lastMessage);
            }
        }
    }, [responseUpdated])

    // Send the conversation to the backend containing the user's latest message
    const sendTranscriptToBackend = async() => {
        setIsLoading(true); // Set the loading state to true as we wait for a response
        const aiTranscript = [
            {role: 'system', content: `You are my friend who only speaks ${language}. You do not comprehend any other languages at all.`},
            ...conversation
        ];
        try {
            const response = await axios.post(`https://linguallect.onrender.com/chat/${language}`, {aiTranscript}, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            });
            dispatch(setBotResponse(response.data)); // Set the bot's response to the response.data
            dispatch(appendToScriptTranscript([{role: 'assistant', content: response.data}])); // Add the bot's message to transcript
            dispatch(appendToConversation([{role: 'assistant', content: response.data}])); // Add the bot's message to conversation
            dispatch(setResponseUpdated(true)); // Update the state of response being updated to true
        } catch(err) {
            console.error('Error sending transcript to backend: ', err)
        } finally {
            setIsLoading(false); // Indicate that loading has finished
        }
    }  

     // Send the conversation to the backend containing the bot's latest message
    const getResponsesFromBackend = async() => {
        dispatch(setFetchingResponses(true)); // Indicate that responses are being fetched
        let success = false; // Flag to indicate if a successful response is received
        while (!success) { // While the response hasn't been retrieved
            try {
                const response = await axios.post(`https://linguallect.onrender.com/script/${language}`, {conversation}, {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                });
                dispatch(setResponses(response.data)); // Update responses with the bot response
                dispatch(setFetchingResponses(false)); // Indicate responses have been fetched
                success = true;
            } catch (err) {
                console.log('Error sending conversation to backend: ', err)
            }
        }
    }

     // Handle user pressing the tick button
    const handleTick = () => {
        const updatedTranscript = transcript.map((item, index) => {
            if (index === transcript.length - 1) {
                return { ...item, content: chosenResponse };
            }
            return item;
        });
        dispatch(setScriptTranscript(updatedTranscript)); // Update the transcript
        dispatch(setConversation(updatedTranscript)); // Update the conversation
        dispatch(setShowTick(false)); // Turn off showing tick
        dispatch(setTranscriptUpdated(true)); // Update the state of transcript to show it is updated
    }

    // // Handle user pressing the retry button
    const handleRetry = () => {
        const updatedTranscript = transcript.slice(0, transcript.length-1);
        dispatch(setScriptTranscript(updatedTranscript)); // Remove the last message from the transcript
        dispatch(setFetching(false)); // Set fetching to false
        dispatch(setShowTick(false)); // Turn off showing tick
    }

    // Toggle the display of the translated conversation
    const toggleTranslation = async() => {
        if (conversation.length > 0) { // Prevent firing on an empty conversation
            const currentConversation = JSON.stringify(conversation); // Convert conversation to a string
            if (currentConversation === previousConversation) { // Check if the current conversation is the same as the previous one
                dispatch(setShowTranslation(!showTranslation)) // Toggle the state of showTranslation
                return; // Exit as to avoid retranslating an already translated conversation
            }
        
            dispatch(setPreviousConversation(currentConversation)); // Update the previousConversation state
            if (!showTranslation) {
                try {
                    setTranslationError(''); // Get rid of any previous error messages
                    dispatch(setFetching(true)); // Set the fetching state to true
                    dispatch(setLoadingTranslation(true)); // Set the LoadingTranslation state to true to show animation
                    const response = await axios.post(`https://linguallect.onrender.com/translate`, {conversation}, {
                        headers: {
                            Authorization: `Bearer ${jwtToken}`
                        }
                    });
                    const translationsArray = Object.values(response.data);
                    if (!(translationsArray[0] === 'Formatting error')) { // As long as the bot returns a valid format response
                        dispatch(setTranslation(translationsArray)); // Update the translation
                    } else { // Otherwise
                        setTranslationError('Unable to retrieve translation at this time.'); // Update the error message
                    }
                } catch (err) {
                    console.log(err)
                    setTranslationError('Unable to retrieve translation.');
                }
                dispatch(setLoadingTranslation(false)); // Update to reflect that the translation has been completed
                dispatch(setFetching(false)); // No longer fetching
            }
            dispatch(setShowTranslation(!showTranslation)); // Toggle the state of displaying the translated text
        }
    }

    return (
        <ScriptStudy
            handleAnswerPress={handleAnswerPress}
            handleAnswerRelease={handleAnswerRelease}
            language={language}
            isLoading={isLoading}
            handleTick={handleTick}
            handleRetry={handleRetry}
            toggleTranslation={toggleTranslation}
            translationError={translationError}
        />
    );
}

export default ScriptStudyContainer;