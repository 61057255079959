import React from "react";
import HomeBody from "./HomeBody";

const Home = () => {
    return (
        <div>
            <HomeBody></HomeBody>
        </div>
    )
}

export default Home;