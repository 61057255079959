import React from "react";

const Login = ({ handleLoginSubmit, handleSignupSubmit, handleInputChange, errors }) => {
    return (
        <div className="row w-100 m-0">
            <div className="col-lg-1"/>
            <div className="col-12 col-lg-5 py-5 left-page">
                <h2 className="w-100">Login</h2>
                <form onSubmit={handleLoginSubmit}>
                    <label htmlFor="lEmail" className="form-label label-text">Email Address</label>
                    <div className="col-12 col-sm-7">
                        <input type="email" name="lEmail" id="lEmail" className="form-control" onChange={handleInputChange} maxLength={100}/>
                    </div>
                    <label htmlFor="lPassword" className="form-label label-text">Password</label>
                    <div className="col-12 col-sm-7">
                        <input type="password" name="lPassword" id="lPassword" className="form-control" onChange={handleInputChange} maxLength={24} minLength={8}/>
                    </div>
                    <p className="text-danger err-text">{errors.login}</p>
                    <button type="submit" className="btn btn-danger mt-3">Login</button>
                </form>
            </div>
            <div className="col-12 col-lg-5 py-5 right-page">
                <h2 className="w-100">Sign Up</h2>
                <form onSubmit={handleSignupSubmit}>
                    <label htmlFor="username" className="form-label label-text">Username</label>
                    <div className="col-12 col-sm-7">
                        <input type="text" name="username" id="username" className="form-control" onChange={handleInputChange} maxLength={24} minLength={5}/>
                    </div>
                    <p className="text-danger err-text">{errors.username}</p>
                    <label htmlFor="password" className="form-label label-text">Password</label>
                    <div className="col-12 col-sm-7">
                        <input type="password" name="password" id="password" className="form-control" onChange={handleInputChange} maxLength={24} minLength={8}/>
                    </div>
                    <p className="text-danger err-text">{errors.password}</p>
                    <label htmlFor="cPassword" className="form-label label-text">Confirm Password</label>
                    <div className="col-12 col-sm-7">
                        <input type="password" name="cPassword" id="cPassword" className="form-control" onChange={handleInputChange} maxLength={24} minLength={8}/>
                    </div>
                    <p className="text-danger err-text">{errors.cPassword}</p>
                    <label htmlFor="email" className="form-label label-text">Email Address</label>
                    <div className="col-12 col-sm-7">
                        <input type="email" name="email" id="email" className="form-control" onChange={handleInputChange} maxLength={100}/>
                    </div>
                    <p className="text-danger err-text">{errors.signup}</p>
                    <button type="submit" className="btn btn-danger mt-3">Sign Up</button>
                </form>
            </div>
            <div className="col-lg-1"/>
        </div>
    )
}

export default Login;