import Header from "../components/Header";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { setUser, setLanguages } from '../reducers/reducers';
import { useNavigate } from "react-router-dom";
import getJwtTokenFromCookie from "../helpers/getJwtToken";
import clearCookie from "../helpers/clearCookie";
import getRefreshTokenFromCookie from "../helpers/getRefreshToken";

const HeaderContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [verificationSent, setVerificationSent] = useState(false);
    const [jwtToken, setJwtToken] = useState(getJwtTokenFromCookie())

    const handleLogout = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://linguallect.onrender.com/logout', {}, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            });
            dispatch(setUser('', false));
            clearCookie('access_token');
            clearCookie('refresh_token');
            navigate('/');
        } catch (err) {
            console.log(err)
            alert('Not logged in');
        }
    };

    const resendVerification = async(e) => {
        e.preventDefault();
        try {
            await axios.post('https://linguallect.onrender.com/reverify', {}, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            })
            setVerificationSent(true);
        } catch(err) {
            alert('Error')
            console.log(err)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://linguallect.onrender.com/user', {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                });
                dispatch(setUser({
                    username: response.data.username,
                    isVerified: response.data.is_verified
                }));
                try {
                    const response = await axios.get('https://linguallect.onrender.com/languages', {
                        headers: {
                            Authorization: `Bearer ${jwtToken}`
                        }
                    });
                    const languages = response.data.map(language => ({
                        name: language.charAt(0).toUpperCase() + language.slice(1),
                        value: language
                    }));
                    dispatch(setLanguages(languages));
                } catch (error) {
                    console.log(error)
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    try {
                        const refresh_token = getRefreshTokenFromCookie();
                        const response = await axios.post('https://linguallect.onrender.com/refresh', {}, {
                            headers: {
                                Authorization: `Bearer ${refresh_token}`
                            },
                            withCredentials: true
                        });
                        dispatch(setUser({
                            username: response.data.username,
                            isVerified: response.data.is_verified
                        }));
                        const new_access_token = response.data.access_token;
                        document.cookie = `access_token=${new_access_token}; Secure; SameSite=Strict`;
                        setJwtToken(new_access_token);
                    } catch (refreshError) {
                        console.error('Error Refreshing Token:', refreshError.message);
                    }
                }
            }
        };
    
        fetchData();
    }, [jwtToken, dispatch]);

    return(
        <Header
            handleLogout={handleLogout}
            resendVerification={resendVerification}
            verificationSent={verificationSent}
        />
    )
}

export default HeaderContainer;