import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import getJwtTokenFromCookie from "../helpers/getJwtToken";

export const fetchUserData = createAsyncThunk(
    'user/fetchUserData',
    async (_, thunkAPI) => {
        try {
            const jwtToken = getJwtTokenFromCookie();
            const response = await axios.get('https://linguallect.onrender.com/user', {
                headers: {
                  Authorization: `Bearer ${jwtToken}`
                }
            });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const initialUserState = {
    username: '',
    isVerified: false,
    status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    error: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        setUser: (state, action) => {
        state.username = action.payload.username;
        state.isVerified = action.payload.isVerified;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchUserData.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(fetchUserData.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.username = action.payload.username;
            state.isVerified = action.payload.is_verified;
        })
        .addCase(fetchUserData.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        });
    },
});

const languagesSlice = createSlice({
  name: 'language',
  initialState: { languages: [] },
  reducers: {
    setLanguages: (state, action) => {
      state.languages = action.payload;
    }
  }
});

const isListeningSlice = createSlice({
    name: 'isListening',
    initialState: false,
    reducers: {
        setIsListening: (state, action) => {
            return action.payload
        }
    }
})

const showTranslationSlice = createSlice({
    name: 'showTranslation',
    initialState: false,
    reducers: {
        setShowTranslation: (state, action) => {
            return action.payload
        }
    }
})

const fetchingSlice = createSlice({
    name: 'fetching',
    initialState: false,
    reducers: {
        setFetching: (state, action) => {
            return action.payload
        }
    }
})

const transcriptUpdatedSlice = createSlice({
    name: 'transcriptUpdated',
    initialState: false,
    reducers: {
        setTranscriptUpdated: (state, action) => {
            return action.payload
        }
    }
})

const responseUpdatedSlice = createSlice({
    name: 'responseUpdated',
    initialState: false,
    reducers: {
        setResponseUpdated: (state, action) => {
            return action.payload
        }
    }
})

const loadingTranslationSlice = createSlice({
    name: 'loadingTranslation',
    initialState: false,
    reducers: {
        setLoadingTranslation: (state, action) => {
            return action.payload
        }
    }
})

const conversationSlice = createSlice({
    name: 'conversation',
    initialState: [],
    reducers: {
        setConversation:  (state, action) => {
            return action.payload
        },
        appendToConversation: (state, action) => {
            return [...state, ...action.payload];
        }
    }
});

const translationSlice = createSlice({
    name: 'translation',
    initialState: [],
    reducers: {
        setTranslation: (state, action) => {
            return action.payload;
        }
    }
});

const previousConversationSlice = createSlice({
    name: 'previousConversation',
    initialState: [],
    reducers: {
        setPreviousConversation: (state, action) => {
            return action.payload;
        }
    }
});

const responsesSlice = createSlice({
    name: 'responses',
    initialState: {
        'response1': '',
        'response2': '',
        'response3': ''
    },
    reducers: {
        setResponses: (state, action) => {
            return action.payload;
        }
    }
});

const chosenResponseSlice = createSlice({
    name: 'chosenResponse',
    initialState: '',
    reducers: {
        setChosenResponse: (state, action) => {
            return action.payload;
        }
    }
});

const showTickSlice = createSlice({
    name: 'showTick',
    initialState: false,
    reducers: {
        setShowTick: (state, action) => {
            return action.payload
        }
    }
})

const botResponseSlice = createSlice({
    name: 'botResponse',
    initialState: '',
    reducers: {
        setBotResponse: (state, action) => {
            return action.payload;
        }
    }
});

const fetchingResponsesSlice = createSlice({
    name: 'fetchingResponses',
    initialState: false,
    reducers: {
        setFetchingResponses: (state, action) => {
            return action.payload
        }
    }
})

const scriptTranscriptSlice = createSlice({
    name: 'scriptTranscript',
    initialState: [],
    reducers: {
        setScriptTranscript: (state, action) => {
            return action.payload;
        },
        appendToScriptTranscript: (state, action) => {
            return [...state, ...action.payload];
        }
    }
})


// Exports
export const { setUser } = userSlice.actions;
export const { setLanguages } = languagesSlice.actions;
export const { setIsListening } = isListeningSlice.actions;
export const { setShowTranslation } = showTranslationSlice.actions;
export const { setFetching } = fetchingSlice.actions;
export const { setTranscriptUpdated } = transcriptUpdatedSlice.actions;
export const { setResponseUpdated } = responseUpdatedSlice.actions;
export const { setLoadingTranslation } = loadingTranslationSlice.actions;
export const { appendToConversation, setConversation } = conversationSlice.actions;
export const { setTranslation } = translationSlice.actions;
export const { setPreviousConversation } = previousConversationSlice.actions;
export const { setResponses } = responsesSlice.actions;
export const { setChosenResponse } = chosenResponseSlice.actions;
export const { setShowTick } = showTickSlice.actions;
export const { setBotResponse } = botResponseSlice.actions;
export const { setFetchingResponses } = fetchingResponsesSlice.actions;
export const { appendToScriptTranscript, setScriptTranscript } = scriptTranscriptSlice.actions;

export const userReducer = userSlice.reducer;
export const languageReducer = languagesSlice.reducer;
export const isListeningReducer = isListeningSlice.reducer;
export const showTranslationReducer = showTranslationSlice.reducer;
export const fetchingReducer = fetchingSlice.reducer;
export const transcriptUpdatedReducer = transcriptUpdatedSlice.reducer;
export const responseUpdatedReducer = responseUpdatedSlice.reducer;
export const loadingTranslationReducer = loadingTranslationSlice.reducer;
export const conversationReducer = conversationSlice.reducer;
export const translationReducer = translationSlice.reducer;
export const previousConversationReducer = previousConversationSlice.reducer;
export const responsesReducer = responsesSlice.reducer;
export const chosenResponseReducer = chosenResponseSlice.reducer;
export const showTickReducer = showTickSlice.reducer;
export const botResponseReducer = botResponseSlice.reducer;
export const fetchingResponsesReducer = fetchingResponsesSlice.reducer;
export const scriptTranscriptReducer = scriptTranscriptSlice.reducer;