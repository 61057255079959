import React, { useEffect, useState } from "react";
import { loadStripe } from '@stripe/stripe-js';
import Buy from "../components/Buy";
import { useParams, useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import getJwtTokenFromCookie from "../helpers/getJwtToken";
import axios from "axios";

const BuyContainer = () => {
    const [stripe, setStripe] = useState(null);
    const [language, setLanguage] = useState("");
    const { language: langParam } = useParams();
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const jwtToken = getJwtTokenFromCookie();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://linguallect.onrender.com/get_pk`, {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                });
                const stripeKey = response.data.stripe_pk;
                const stripeInstance = await loadStripe(stripeKey);
                setStripe(stripeInstance);

                const lowerLanguage = langParam.toLowerCase();
                setLanguage(lowerLanguage);
            } catch (err) {
                console.error("Error fetching Stripe public key:", err);
            }
        };

        fetchData();
    }, [jwtToken, langParam]);

    useEffect(() => {
        if (!user.username) {
            navigate('/');
        }
    }, [user, navigate]);

    return (
        <div>
            <Elements stripe={stripe}>
                {stripe && <Buy 
                    languagePack={language}
                    language={langParam}
                />}
            </Elements>
        </div>
    );
};

export default BuyContainer;