import Login from "../components/Login";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setUser, setLanguages } from '../reducers/reducers';
import axios from "axios";

const LoginContainer = () => {
    const [errors, setErrors] = useState({
        login: '',
        username: '',
        password: '',
        cPassword: '',
        email: '',
    });
    const [loginData, setLoginData] = useState({
        lEmail: '',
        lPassword: ''
    });
    const [signupData, setSignupData] = useState({
        username: "",
        password: "",
        cPassword: "",
        email: "",
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validateUsername = (username) => {
        return username.length >= 5;
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const validatePassword = (password) => {
        const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/; // Password must be at least 8 characters long and contain at least one capital letter and one number
        return regex.test(password);
    };

    const getUserRedirect = async(access_token) => {
        const response = await axios.get('https://linguallect.onrender.com/user', {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        })
        dispatch(setUser({
            username: response.data.username,
            isVerified: response.data.is_verified
        }));
        navigate('/');
    }

    const handleLoginSubmit = async(e) => {
        e.preventDefault();

        if (!loginData.lEmail || !loginData.lPassword) {
            setErrors({ 'login': 'Email and password are required.' });
            return;
        }

        try {
            const response = await axios.post('https://linguallect.onrender.com/login', loginData, {
                withCredentials: true,
            });
            if (response.status === 200) {
                const { access_token, refresh_token } = response.data;
                document.cookie = `access_token=${access_token}; Secure; SameSite=Strict`;
                document.cookie = `refresh_token=${refresh_token}; Secure; SameSite=Strict`;
                getUserRedirect(access_token);
                try {
                    const response = await axios.get('https://linguallect.onrender.com/languages', {
                        headers: {
                            Authorization: `Bearer ${access_token}`
                        }
                    });
                    const languages = response.data.map(language => ({
                        name: language.charAt(0).toUpperCase() + language.slice(1),
                        value: language
                    }));
                    dispatch(setLanguages(languages));
                } catch (error) {
                    console.log(error)
                }
            } else {
                setErrors({ 'login': 'Incorrect username or password.' });
            }
        } catch (error) {
            setErrors({ 'login': 'Incorrect username or password.' });
        }
    }

    const handleSignupSubmit = async(e) => {
        e.preventDefault();

        if (!validateUsername(signupData.username)) {
            setErrors({'username': 'Username must be at least 5 characters long.'});
            return;
        }
        if (!validateEmail(signupData.email)) {
            setErrors({'email': 'Please enter a valid email address.'});
            return;
        }
        if (!validatePassword(signupData.password)) {
            return;
        }
        if (signupData.password !== signupData.cPassword) {
            setErrors({'cPassword': 'Passwords do not match.'});
            return;
        }
        try {
            const response = await axios.post('https://linguallect.onrender.com/register', signupData, {
                withCredentials: true
            });
            if (response.status === 200) {
                const { access_token } = response.data;
                document.cookie = `access_token=${access_token}; Secure; SameSite=Strict`;
                getUserRedirect(access_token);
            }
        } catch (err) {
            setErrors({'signup': err.response.data.error});
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (['lEmail', 'lPassword'].includes(name)) {
            setLoginData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        } else {
            setSignupData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    return (
        <Login 
            handleLoginSubmit={handleLoginSubmit}
            handleSignupSubmit={handleSignupSubmit}
            handleInputChange={handleInputChange}
            errors={errors}
        />
    )
}

export default LoginContainer;