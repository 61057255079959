import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import getJwtTokenFromCookie from "../helpers/getJwtToken";
import { setLanguages } from '../reducers/reducers';

const Buy = ({ languagePack, language }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [ownedLanguages, setOwnedLanguages] = useState([]);
    const [duplicateLanguage, setDuplicateLanguage] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const jwtToken = getJwtTokenFromCookie();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setProcessing(true);
    
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });
    
        if (error) {
            setError(error.message);
            setProcessing(false);
        } else {
            try {
                const response = await axios.post(
                    `https://linguallect.onrender.com/buy/${languagePack}`,
                    { payment_method: paymentMethod.id }, {
                        headers: {
                            Authorization: `Bearer ${jwtToken}`
                        }
                    }
                );
                try {
                    const response = await axios.get('https://linguallect.onrender.com/languages', {
                        headers: {
                            Authorization: `Bearer ${jwtToken}`
                        }
                    });
                    const languages = response.data.map(language => ({
                        name: language.charAt(0).toUpperCase() + language.slice(1),
                        value: language
                    }));
                    dispatch(setLanguages(languages));
                } catch (err) {
                    console.log(err);
                    setError('Error processing payment.');
                }       
                console.log(response.data);
                alert('Payment successful!');
                navigate('/');
            } catch (err) {
                console.log(err)
                setError('Error processing payment.');
            }
            setProcessing(false);
        }
    };
   
    useEffect(() => {
        const fetchData = async () => {
            try {
                const jwtToken = getJwtTokenFromCookie();
                const response = await axios.get('https://linguallect.onrender.com/languages', {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                });
                const languages = response.data.map(language => ({
                    name: language.charAt(0).toUpperCase() + language.slice(1),
                    value: language
                }));
                setOwnedLanguages(languages);
            } catch (error) {
                console.log(error);
            }
        };
    
        fetchData();
    }, []);

    useEffect(() => {
        const isLanguagePackOwned = ownedLanguages.some(lang => lang.value === languagePack);
        if (isLanguagePackOwned) {
            setDuplicateLanguage(true);
        }
    }, [languagePack, ownedLanguages]);

    return (
        <main>
            <div className="row mx-0">
                <div className="col-lg-1" />
                <div className="left-page col-lg-5 py-5">
                    <h2>You are about to subscribe to the {language} language pack.</h2>
                    <p>Begin practicing {language} without the need for a language partner! </p>
                    <p>By subscribing to the {language} Linguallect language pack you agree to pay £3.99 per month to maintain your subscription.</p>
                    <p>Subscribing to a language pack entitles you to one month access, after which your subscription will be automatically renewed.</p>
                    <p>You are free to cancel at any time, and enjoy access to the language pack for the remainder of the month.</p>
                </div>
                <div className="right-page col-lg-5 py-5">
                    {!duplicateLanguage ? 
                        <>
                            <h2>Payment details</h2>
                            <form onSubmit={handleSubmit}>
                                <CardElement />
                                <button className="my-4 btn btn-danger" type="submit" disabled={!stripe || processing}>
                                    {processing ? 'Processing...' : 'Subscribe'}
                                </button>
                                {error && <div className="text-danger err-text">{error}</div>}
                            </form>
                        </>
                    :
                        <h2>You already own this language!</h2>
                    }
                </div>
            </div>
        </main>
    );
}

export default Buy;