import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector } from 'react-redux';
import { fetchUserData } from "../reducers/reducers";
import { useDispatch } from "react-redux";

const Header = ({ handleLogout, resendVerification, verificationSent }) => {
    const user = useSelector(state => state.user);  
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUserData());
    }, [dispatch]);
 
    const isLoading = () => {
        switch (user.status) {
            case 'loading':
                return true;
            default:
                return false;
        }
    };


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className="">
            <script src="https://js.stripe.com/v3/"></script>
            <nav className="navbar navbar-expand-lg navbar-dark bg-danger">
                <div className="col-1"/>
                <div className="col-lg-2 col-5 ps-2">
                    <a className="nav-link" href="/">Linguallect</a>
                </div>
                <div className="col-lg-2 col-5 text-end">
                    <button className="navbar-toggler" type="button" onClick={toggleDropdown}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="col-1"/>
                {isLoading() ? 
                    null
                :
                    <>
                        <div className={`mx-0 collapse navbar-collapse col-lg-4 col-5 text-center ${isOpen ? 'show' : 'row'}`} id="navbar-nav">
                            {user.username 
                            ? 
                                isOpen?
                                    <div className="row w-100 d-flex justify-content-between mx-0 p-0">
                                        <a className="nav-link col-12 col-md-3" href="/about">About</a>
                                        <a className="nav-link col-12 col-md-3 " href="/store">Store</a>
                                        <a className="nav-link col-12 col-md-3 " href="/account">Account</a>
                                        <a className="nav-link col-12 col-md-3 " href="/" onClick={handleLogout}>Logout</a> 
                                    </div>
                                :
                                    <div className="row w-100 d-flex justify-content-between mx-0 p-0">
                                        <a className="nav-link col-md-3 text-end" href="/about">About</a>
                                        <a className="nav-link col-md-3 text-end" href="/store">Store</a>
                                        <a className="nav-link col-md-3 ps-2 text-end" href="/account">Account</a>
                                        <a className="nav-link col-md-3 ps-3 text-end" href="/" onClick={handleLogout}>Logout</a> 
                                    </div>
                            : 
                                isOpen?
                                    <div className="row mx-0 w-100">
                                        <div className="col-lg-5"/>
                                        <a className="nav-link col-12 col-md-6 col-lg-5" href="/about">About</a>
                                        <a className="nav-link col-12 col-md-6 col-lg-2" href="/login">Login</a>
                                    </div>
                                :
                                <div className="row p-0">
                                    <div className="col-lg-5"/>
                                    <a className="nav-link col-12 col-md-6 col-lg-5 text-end" href="/about">About</a>
                                    <a className="nav-link col-12 col-md-6 col-lg-2 text-end ps-1" href="/login">Login</a>
                                </div>
                            }
                        </div>
                        <div className="col-1"/>
                    </>
                }
                
            </nav>
            <div className="col-12">
                { user.username && !user.isVerified && !verificationSent ? (
                    <p className="text-center err-text test bg-warning">
                        You must verify your email before using Linguallect.{' '}
                        <a className="err-text" href="/" onClick={resendVerification}>
                            Resend verification?
                        </a>
                    </p>
                ) : (
                    user.username && !user.isVerified && verificationSent ? (
                        <p className="text-center err-text test bg-warning">Verification email sent.</p>
                ) : (
                    null
                ))}
            </div>
        </header>
    );
};

export default Header;
