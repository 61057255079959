import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Home from './components/Homepage';
import Layout from './components/Layout';
import FreeStudyContainer from './containers/FreeStudyContainer';
import ScriptStudyContainer from './containers/ScriptStudyContainer';
import LoginContainer from './containers/LoginContainer';
import Verify from './components/Verify';
import Store from './components/Store';
import BuyContainer from './containers/BuyContainer';
import AccountContainer from './containers/AccountContainer';
import About from './components/About';

function App() {
  const user = useSelector(state => state.user);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/study/:language" element={user ? <FreeStudyContainer /> : <Navigate to='/login'/>} />
        <Route path="/script_study/:language" element={user ? <ScriptStudyContainer /> : <Navigate to='/login'/>} />
        <Route path="/login" element={<LoginContainer />} />
        <Route path="/account" element={user ? <AccountContainer /> : <Navigate to='/login'/>} />
        <Route path="/verify/:token" element={<Verify />}/>
        <Route path="/store" element={<Store />}/>
        <Route path="/buy/:language" element={user ? <BuyContainer /> : <Navigate to='/login'/>} />
      </Routes>
    </Layout>
  );
}

export default App;
