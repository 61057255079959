import React, { useState, useEffect } from "react";
import Account from "../components/Account";
import axios from "axios";
import getJwtTokenFromCookie from "../helpers/getJwtToken";
import { setUser, setLanguages } from '../reducers/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import clearCookie from "../helpers/clearCookie";

const AccountContainer = () => {
    const [emailData, setEmailData] = useState({
        oldEmail: '',
        newEmail: ''
    });
    const [passwordData, setPasswordData] = useState({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    });
    const [passwordRequired, setPasswordRequired] = useState()
    const [errors, setErrors] = useState({
        email: '',
        password: '',
        subscriptions: '',
        deletion: ''
    });
    const [success, setSuccess] = useState({
        email: '',
        password: '',
        subscriptions: ''
    });
    const [languageCount, setLanguageCount] = useState(0)
    const [showSubscription, setShowSubscription] = useState(false);
    const [showDeletion, setShowDeletion] = useState(false);
    const [displayLanguage, setDisplayLanguage] = useState()
    const [language, setLanguage] = useState()

    const jwtToken = getJwtTokenFromCookie();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const languages = useSelector(state => state.languages.languages);

    const removeSubscription = (removeLanguage) => {
        const updatedLanguages = languages.filter(sub => sub.value !== removeLanguage);
        dispatch(setLanguages(updatedLanguages));
    };

    const handleClose = () => {
        setShowSubscription(false);
        setShowDeletion(false);
        setDisplayLanguage();
        setLanguage();
        setErrors((prevData) => ({
            ...prevData,
            subscriptions: '',
            deletion: ''
        }));
    };
    
    const handleShowSubscription = (e) => {
        setShowSubscription(true);
        setDisplayLanguage(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))
        setLanguage(e.target.value)
    }

    const handleShowDeletion = (e) => {
        setShowDeletion(true);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (['oldEmail', 'newEmail'].includes(name)) {
            setEmailData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        } else if (['oldPassword', 'newPassword', 'confirmNewPassword'].includes(name)) {
            setPasswordData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        } else {
            setPasswordRequired(value);
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleFormSubmit = async(e) => {
        const formName = e.target.name
        const formData = formName === "email" ? emailData : passwordData;
        e.preventDefault();
        try {
            const response = await axios.post(`https://linguallect.onrender.com/update_${formName}`, formData, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            })
            if (response.status === 200) {
                setSuccess({ [formName]: `${formName.charAt(0).toUpperCase()}${formName.slice(1)} updated successfully!`})
                setErrors({ [formName]: '' });
            } else {
                setSuccess({ [formName]: ''})
                setErrors({ [formName]: response });
            }
        } catch (error) {
            setErrors({ [formName]: error.response.data['error'] });
        }
    }

    // Consider using phrase instead of password?
    const handleSubscription = async(e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://linguallect.onrender.com/check_password', { password: passwordRequired }, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            })
            try {
                const resp = await axios.post(`https://linguallect.onrender.com/manage_subscriptions/${language}`, {}, {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                })
                if (resp.status === 200) {
                    setSuccess({ 'subscriptions': 'Subscription cancelled successfully!'})
                    handleClose();
                    removeSubscription('language');
                    setLanguageCount(languageCount - 1);
                } else {
                    setSuccess({ 'subscriptions': '' });
                    setErrors({ 'subscriptions': 'Unable to cancel subscription at this time. Please contact support.'})
                }
            } catch (error) {
                setErrors({ ['subscriptions']: error.resp.data['error'] });
            }
        } catch {
            setErrors({ 'subscriptions': 'Incorrect password.'})
            setSuccess({ 'subscriptions': '' });
        }        
    }

    const handleDeletion = async(e) => { 
        e.preventDefault();
        try {
            const response = await axios.post('https://linguallect.onrender.com/check_password', { password: passwordRequired }, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            })
            try {
                const response = await axios.post('https://linguallect.onrender.com/delete_account', {}, {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                })
                console.log(response)
                if (response.status === 200){
                    dispatch(setUser('', false));
                    clearCookie('access_token');
                    navigate('/');
                } else {
                    setErrors({ 'deletion': 'Unable to delete account at this time. Please wait and try again later.'})
                }
            } catch (error) {
                setErrors({ ['subscriptions']: error.resp.data['error'] });
            }
        } catch {
            setErrors({ 'deletion': 'Incorrect password.'})
        }
    }

    useEffect(() => {
        const fetchData = async() => {
            try {
                const jwtToken = getJwtTokenFromCookie();
                const response = await axios.get('https://linguallect.onrender.com/languages', {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                });
                const languages = response.data.map(language => ({
                    name: language.charAt(0).toUpperCase() + language.slice(1),
                    value: language
                }));
                dispatch(setLanguages(languages));
            } catch (error) {
                console.log(error)
            }
        };

        fetchData();
    }, [languageCount]);

    return(
        <Account
            handleInputChange={handleInputChange}
            handleFormSubmit={handleFormSubmit}
            errors={errors}
            success={success}
            handleSubscription={handleSubscription}
            handleDeletion={handleDeletion}
            handleClose={handleClose}
            handleShowDeletion={handleShowDeletion}
            handleShowSubscription={handleShowSubscription}
            showSubscription={showSubscription}
            showDeletion={showDeletion}
            displayLanguage={displayLanguage}
        />
    )
}


export default AccountContainer