import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import getJwtTokenFromCookie from "../helpers/getJwtToken";

const Store = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async() => {
            try {
                const jwtToken = getJwtTokenFromCookie();
                const response = await axios.get('https://linguallect.onrender.com/languages', {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                })
            } catch (error) {
                console.log(error)
            }
        };

        fetchData();
    }, []);

    const toBuy = (e) => {
        navigate(`/buy/${e.target.value}`)
    }

    return (
        <main>
            <div className="w-100 mx-0 row">
                <div className="col-lg-1"/>
                <div className="col-12 col-lg-10">
                    <div className="row mt-4">
                        <div className="col">
                            <h3>BEFORE YOU BUY</h3>
                            <p className="justify">Please note that using Linguallect <b>REQUIRES</b> you possess a basic speaking level in your target language. To learn more
                            about how Linguallect works, why you need to possess some speaking abilty and what you can expect from the app, please check the <a href='/about'>
                            about</a> page. <b>NOTE:</b> Firefox and Opera are currently unsupported. Please use desktop for best experience.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4 mt-4">
                            <h3>French</h3>
                            <button className="btn btn-danger col-12" value="French" onClick={toBuy}>Buy</button>
                        </div>
                        <div className="col-12 col-md-4 mt-4">
                            <div className="col">
                                <h3>Italian</h3>
                                <button className="btn btn-danger col-12" value="Italian" onClick={toBuy}>Buy</button>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mt-4">
                            <div className="col">
                                <h3>Japanese</h3>
                                <button className="btn btn-danger col-12" value="Japanese" onClick={toBuy}>Buy</button>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex">
                        <div className="col-12 col-md-4 mt-4">
                            <div className="col">
                                <h3>Mandarin</h3>
                                <button className="btn btn-danger col-12" value="Mandarin" onClick={toBuy}>Buy</button>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mt-4 my-5 pb-5">
                            <div className="col">
                                <h3>Spanish</h3>
                                <button className="btn btn-danger col-12" value="Spanish" onClick={toBuy}>Buy</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-1"/>
            </div>
        </main>
    )
}

export default Store;