import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import getJwtTokenFromCookie from "../helpers/getJwtToken";
import { fetchUserData } from "../reducers/reducers";

const HomeBody = () => {
    const [selectedLanguageFreeChat, setSelectedLanguageFreeChat] = useState('');
    const [selectedLanguageScriptChat, setSelectedLanguageScriptChat] = useState('');
    const user = useSelector(state => state.user);
    const languages = useSelector(state => state.languages.languages);
    const navigate = useNavigate();
    const jwtToken = getJwtTokenFromCookie();
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchUserData());
    }, [dispatch]);
 
    const isLoading = () => {
        switch (user.status) {
            case 'loading':
                return true;
            default:
                return false;
        }
    };

    const handleButtonClick = () => {
        navigate('/login');
    }

    const handleChangeFreeChat = (e) => {
        setSelectedLanguageFreeChat(e.target.value);
    };

    const handleChangeScriptChat = (e) => {
        setSelectedLanguageScriptChat(e.target.value);
    };

    const freeStudyLang = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.get(`https://linguallect.onrender.com/chat/${selectedLanguageFreeChat}`, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            });
            if (response.data.code === 302) {
                navigate('/store')
            } else {
                navigate(`/study/${selectedLanguageFreeChat}`);
            }
        } catch (error) {
            console.error(`Error accessing ${selectedLanguageFreeChat} study page:`, error);
        }
    };

    const scriptStudyLang = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.get(`https://linguallect.onrender.com/chat/${selectedLanguageScriptChat}`, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            });
            if (response.data.code === 302) {
                navigate('/store')
            } else {
                navigate(`/script_study/${selectedLanguageScriptChat}`);
            }
        } catch (error) {
            console.error(`Error accessing ${selectedLanguageScriptChat} study page:`, error);
        }
    };

    return (
        <main className="w-100">
            {isLoading() === true ? 
                <div className="w-100 bg-white" id="loader-screen"/>
            :
            <div className="row mx-0 mt-4">
                <div className="col-lg-1"/>
                    { user.username 
                        ? 
                        <div className="col-12 col-lg-5 py-5 d-flex align-items-center left-page text-center border rouded">
                            <h1 className="text-center w-100">Welcome, {user.username}!</h1>
                        </div>
                        : 
                        <div className="col-12 col-lg-5 py-5 d-flex align-items-center left-page text-center">
                            <h1 className="text-center">Welcome to Linguallect!</h1>
                        </div>
                    }
                <div className="col-12 col-lg-5 py-4 right-page ">
                    { user.username 
                        ? <div className="row d-flex justify-content-between m-0">
                            <div className="free-chat col-12 col-sm-5 col-lg-10 col-xl-5 border rounded mx-auto mb-4">
                                <h3 className="mt-3">Free Chat</h3>
                                <form onSubmit={freeStudyLang}>
                                    <select className="w-100 form-select mb-4" value={selectedLanguageFreeChat} onChange={handleChangeFreeChat}>
                                        <option value="" default disabled>Language</option>
                                        {languages.map((language, index) => (
                                            <option value={language.value} key={index}>{language.name}</option>
                                        ))}
                                    </select>
                                    <button className="btn lang-btn mt-5 mb-4" type="submit">Free Chat</button>
                                </form>
                            </div>
                            <div className="script-chat col-12 col-sm-5 col-lg-10 col-xl-5 border rounded mx-auto mb-4" id="script-chat-container">
                                <h3 className="mt-3">Script Chat</h3>
                                <form onSubmit={scriptStudyLang}>
                                    <select className="w-100 form-select mb-4" value={selectedLanguageScriptChat} onChange={handleChangeScriptChat}>
                                        <option value="" default disabled>Language</option>
                                        {languages.map((language, index) => (
                                            <option value={language.value} key={index}>{language.name}</option>
                                        ))}
                                    </select>
                                    <button className="btn lang-btn mt-5 mb-4" type="submit">Script Chat</button>
                                </form>
                            </div>
                        </div>
                        : <div>
                            <h2>What Is Linguallect?</h2>
                            <p className="justify">
                                Linguallect is a new way to practice speaking a new language! If you've ever struggled to practice a language without a language partner, then 
                                look no further. Linguallect utilises AI technology to hold conversations with you across a number of languages. Choose to have totally free
                                flowing conversations or select from responses generated for you! <br/> <b>NOTE:</b> Firefox and Opera are currently unsupported. Please use desktop
                                for best experience.
                            </p>

                            <div className="d-flex justify-content-center">
                                <button className="mt-2 w-75 bg-danger text-light" id="get-started-btn" href="/login" onClick={handleButtonClick}>
                                    Get Started!
                                </button>
                            </div>
                        </div>
                    }
                </div>
                {user.username ?
                    <div className="mt-3 mx-0 px-0">
                        <div className="row col-12 col-sm-10 mx-auto mt-4 justify">
                            <p>Looking to dive right into conversation? Then choose your language and get started with our free chat mode. With free chat, you and the bot can have a
                            conversation about whatever you want at your own pace. Script chat offers a different approach, challenging both your reading and speaking! Choose 
                            from 3 responses and get speaking to our bot. <br/> <b>NOTE:</b> Firefox and Opera are currently unsupported. Please use desktop for best experience.
                            </p>
                        </div>
                    </div>
                :
                    null
                }
                
            </div>
            }
        </main>
    );
};

export default HomeBody;