import React from "react";
import { useSelector } from "react-redux";

const FreeStudy = ({ handleMicPress, handleMicRelease, toggleTranslation, translationError, isLoading, handleRetry }) => {
    const isListening = useSelector((state) => state.isListening);
    const showTranslation = useSelector((state) => state.showTranslation);
    const loadingTranslation = useSelector((state) => state.loadingTranslation);
    const conversation = useSelector((state) => state.conversation);
    const translation = useSelector((state) => state.translation);
    const showTick = useSelector((state) => state.showTick);

    function getLastUserMessageIndex(conversation) {
        for (let i = conversation.length - 1; i >= 0; i--) {
            if (conversation[i].role === 'user') {
                return i;
            }
        }
        return -1;
    }

    return(
        <div className="">
            <div className="col-sm-1"/>
            <div className="row border col-sm-10 mx-auto" id="chatbox">
                <div className="messages col-12 p-0">
                    {!showTranslation ? 
                        conversation.map((message, index) => (
                            <div key={index}>
                                {message.role === 'user' && isLoading && index === getLastUserMessageIndex(conversation) ?
                                    <>
                                        <p className="text-end px-2" id={index}>
                                            {index === getLastUserMessageIndex(conversation) && showTick &&
                                                <>
                                                    <span className="fa-solid fa-rotate-right me-3 hover-zoom" onClick={handleRetry} onTouchEnd={handleRetry}></span>
                                                </>
                                            }
                                            {message.content}
                                        </p>   
                                        <div className="loader mb-3 px-2">
                                            <span className="loader__element"></span>
                                            <span className="loader__element"></span>
                                            <span className="loader__element"></span>
                                        </div>
                                    </>
                                :
                                    message.role === 'user' ?
                                        <p className="text-end px-2" id={index}>
                                            {index === getLastUserMessageIndex(conversation) && showTick &&
                                                <>
                                                    <span className="fa-solid fa-rotate-right me-3 hover-zoom" onClick={handleRetry} onTouchEnd={handleRetry}></span>
                                                </>
                                            }
                                            {message.content}
                                        </p>   
                                    :
                                        <p className="px-2">{message.content}</p>
                                }
                            </div>
                        ))       
                    :
                        !translationError ? 
                            translation.map((message, index) => (
                                <div key={index}>
                                    {message.role === 'assistant'
                                        ? 
                                        index === conversation.length - 1 && isLoading ?
                                            <div className="loader mb-3">
                                                <span className="loader__element"></span>
                                                <span className="loader__element"></span>
                                                <span className="loader__element"></span>
                                            </div>
                                            :
                                            <p className="px-2">{message.content}</p>
                                        : 
                                        <p className="text-end px-2">{message.content}</p>
                                    }
                                </div>
                            ))
                        :
                            <p className="px-2 text-center text-danger">{translationError}</p>
                    }
                    <button className="border border-dark w-100 m-0 bg-dark text-white row py-1 translate-btn" onClick={toggleTranslation}>
                        {loadingTranslation?
                        <div className="translate-loader d-flex justify-content-center">
                            <span className="loader__element"></span>
                            <span className="loader__element"></span>
                            <span className="loader__element"></span>
                        </div>
                        :
                        <span className="">Translate Conversation</span>
                        }
                    </button>
                </div>
            </div>
            <div className="col-sm-1"/>
            <div className="interact w-100 d-flex justify-content-center mt-4">
                <button className="bg-danger text-white btn col-12 col-sm-10" id="mic-btn" onMouseDown={handleMicPress} onMouseUp={handleMicRelease}
                    onTouchStart={handleMicPress} onTouchEnd={handleMicRelease}>
                    {isListening ? "Stop Listening" : "Start Listening"}
                </button>
            </div>
        </div>
    )
};

export default FreeStudy;