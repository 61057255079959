import { combineReducers } from 'redux';
import { userReducer,
  languageReducer,
  isListeningReducer,
  showTranslationReducer,
  fetchingReducer,
  transcriptUpdatedReducer,
  responseUpdatedReducer,
  loadingTranslationReducer,
  conversationReducer,
  translationReducer,
  previousConversationReducer,
  responsesReducer,
  chosenResponseReducer,
  botResponseReducer,
  fetchingResponsesReducer,
  scriptTranscriptReducer,
  showTickReducer,
} from './reducers';

const rootReducer = combineReducers({
  user: userReducer,
  languages: languageReducer,
  isListening: isListeningReducer,
  showTranslation: showTranslationReducer,
  fetching: fetchingReducer,
  transcriptUpdated: transcriptUpdatedReducer,
  responseUpdated: responseUpdatedReducer,
  loadingTranslation: loadingTranslationReducer,
  conversation: conversationReducer,
  translation: translationReducer,
  previousConversation: previousConversationReducer,
  responses: responsesReducer,
  chosenResponse: chosenResponseReducer,
  botResponse: botResponseReducer,
  fetchingResponses: fetchingResponsesReducer,
  scriptTranscript: scriptTranscriptReducer,
  showTick: showTickReducer,

});

export default rootReducer;
