import React from "react";
import HeaderContainer from "../containers/HeaderContainer";
import HomeFooter from "./Footer"

const Layout = ({ children }) => {
    return (
        <div>
            <HeaderContainer />
            {children}
            <HomeFooter />
        </div>
    );
};

export default Layout;