import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

const Account = ({ handleInputChange, handleFormSubmit, errors, success, handleSubscription, handleDeletion, handleClose, handleShowSubscription, 
                   handleShowDeletion, showSubscription, showDeletion, displayLanguage }) => {
    const languages = useSelector(state => state.languages.languages);
    return(
        <div className="row mx-0">
            <div className="col-lg-1"/>
            <div className="col-12 col-lg-10">
                <h2 className="mt-4">General</h2>
                <div>
                    <div id="emailForm">
                        <b>Email</b>
                        <p className="justify">To update your email address, please enter your current email address and the new email address.</p>
                        <form className="row" name="email" onSubmit={handleFormSubmit}>
                            <div className="col-12 col-md-9 col-lg-6">
                                <label htmlFor="oldEmail" className="form-label mt-2 label-text">Current Email Address</label>
                                <input type="email" name="oldEmail" id="oldEmail" className="form-control" onChange={handleInputChange} maxLength={100}/>
                            </div>
                            <div className="col-12 col-md-9 col-lg-6">
                                <label htmlFor="newEmail" className="form-label mt-2 label-text">New Email Address</label>
                                <input type="email" name="newEmail" id="newEmail" className="form-control" onChange={handleInputChange} maxLength={100}/>
                            </div>
                            <div className="col-12 col-md-9 col-lg-6 mt-2">
                                <button className="btn btn-danger col-12 col-sm-6" type="submit">Update Email</button>
                            </div>
                        </form>
                        {errors.email
                        ?
                            <p className="text-danger err-text">{errors.email}</p>
                        :
                        success.email ?
                            <p className="text-success success-text">{success.email}</p>
                        :
                        null
                        }
                    </div>
                    <div id="passwordForm" className="mt-4">
                        <b>Password</b>
                        <p className="justify">To update your password, please enter your current password and the new password. Please also confirm your new password to make sure 
                        you're setting it to what you really want!
                        </p>
                        <form className="row" name="password" onSubmit={handleFormSubmit}>
                            <div className="col-12 col-md-9 col-lg-4">
                                <label htmlFor="oldPassword" className="form-label mt-2 label-text">Current Password </label>
                                <input type="password" name="oldPassword" id="oldPassword" className="form-control" onChange={handleInputChange} maxLength={24} minLength={8}/>
                            </div>
                            <div className="col-12 col-md-9 col-lg-4">
                                <label htmlFor="newPassword" className="form-label mt-2 label-text">New Password</label>
                                <input type="password" name="newPassword" id="newPassword" className="form-control" onChange={handleInputChange} maxLength={24} minLength={8}/>
                            </div>
                            <div className="col-12 col-md-9 col-lg-4">
                                <label htmlFor="confirmNewPassword" className="form-label mt-2 label-text">Confirm New Password</label>
                                <input type="password" name="confirmNewPassword" id="confirmNewPassword" className="form-control" onChange={handleInputChange} maxLength={24} minLength={8}/>
                            </div>
                            <div className="col-12 col-md-9 col-lg-6 mt-2">
                                <button className="btn btn-danger col-12 col-sm-6" type="submit">Update Password</button>
                            </div>
                        </form>
                        {errors.password
                        ?
                            <p className="text-danger err-text">{errors.password}</p>
                        :
                        success.password ?
                            <p className="text-success success-text">{success.password}</p>
                        :
                        null
                        }
                    </div>
                </div>

                <h2 className="mt-4">Subscriptions</h2>
                <div>
                {languages.length > 0 ? (
                    <div>
                        <p className="justify">Here are all your active subscriptions. If you choose to cancel a subscription, it will remain active on your account until the end 
                        of this month's billing cycle.</p>
                        {languages.map((subscription, index) => (
                            <div className="row mx-0" key={index}>
                                <p className="col-6 col-md-3 col-lg-3 col-xl-2 px-0">{subscription.name}</p>
                                <Button className="col-6 col-md-3 col-lg-2 btn btn-danger border h-25 py-0" value={subscription.value} onClick={handleShowSubscription}>
                                    Cancel
                                </Button>
                            </div>
                        ))}
                        {success.subscriptions
                        &&
                            <p className="text-success err-text">{success.subscriptions}</p>
                        }
                    </div>
                ) : <p className="justify"> Your active subscriptions will appear here for you to manage. </p>
                }                        
                </div>

                <h2 className="mt-4">Danger Zone</h2>
                <div id="deleteAccount">
                    <b>Delete Account</b>
                    <p className="justify">Deleting your account is an irreversible action. Doing so will IMMEDIATELY cancel all your subscriptions and permanently delete your 
                    Linguallect account.</p>
                    <div className="col-12 col-md-9 col-lg-6 mt-2">
                        <Button className="col-12 col-sm-6 btn btn-danger border h-75 mb-2" onClick={handleShowDeletion}>
                            Delete Account
                        </Button>
                    </div>
                </div>
            </div>
            <div className="col-lg-1"/>
            <Modal show={showSubscription} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel {displayLanguage} subscription?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Please enter your password to confirm you wish to end your {displayLanguage} subscription.
                    <form onSubmit={handleSubscription} id="subscriptionForm">
                        <input className="form-control" name="password" type="password" onChange={handleInputChange} autoComplete="off" maxLength={24} minLength={8}></input>    
                    </form>
                    {errors.subscriptions
                        &&
                            <p className="text-danger err-text">{errors.subscriptions}</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" type="submit" form="subscriptionForm">
                        Cancel Subscription
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeletion} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete your account? This action is irreversible!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Please enter your password to confirm you wish to delete your Linguallect account.
                    <form onSubmit={handleDeletion} id="deletionForm">
                        <input className="form-control" name="password" type="password" onChange={handleInputChange} autoComplete="off" maxLength={24} minLength={8}></input>    
                    </form>
                    {errors.deletion
                        &&
                            <p className="text-danger err-text">{errors.deletion}</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" type="submit" form="deletionForm">
                        Delete Account
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Account;