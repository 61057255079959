import React from "react";

const About = () => {
    return(
        <div className="row m-0">
            <div className="col-lg-1"/>
            <div className="col-12 col-lg-10 mb-4 py-2">
                <div className="row">
                {/* <iframe width="456" height="811" src="" title="" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe> */}
                </div>
                <div>
                    <h2 className="mt-4">What is Linguallect?</h2>
                    <p className="justify">Linguallect is an AI based practice platform that focuses on helping you improve your speaking proficiency in a number of languages. 
                    With the help of AI, Linguallect will have you conversing about a number of topics and developing your speaking skills at a faster rate. <b>NOTE:</b> Firefox 
                    and Opera are currently unsupported. Please use desktop for best experience.</p>
                </div>
                <div>
                <h2 className="mt-4">How does it work?</h2>
                    <p className="justify">By repeatedly engaging in conversation in your target language, you become accustomed to forming sentences quicker. With the stress and 
                    anxiety of talking to a native speaker taken out of the equation, you're free to practice at your own pace. As you get comfortable, you'll notice yourself
                    responding faster and relying on translations less often. You may even find yourself picking up new vocabulary as your conversations get more complex! </p>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="mt-4 w-100 mx-0">
                            <div className="col-12">
                                <h3 className="mt-0">Free Chat</h3>
                                <b>What is it?</b>
                                <p className="justify">Free Chat is similar to regular conversation, minus the awkwardness... Just like in real life, take turns conversing 
                                freely (hence the name) with the bot about any topic of your choice, the only limit is your imagination! To get started, simply press and hold the 
                                microphone button to say hello, and the bot will respond automatically. From there, it's up to you where the conversation goes.
                                </p>
                            </div>
                            <div className="col-12 mx-auto bg-danger">
                                <div className="row mx-1">
                                    <img className="my-3 gif" src="/freeChatAnimation.gif"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="mt-4 w-100 mx-0">
                            <div className="col-12">
                                <h3>Script Chat</h3>
                                <b>What is it?</b>
                                <p className="justify">Script Chat challenges both your speaking and reading abilities. In Script Chat, the bot kicks off the conversation, and you 
                                will receive 3 responses which you can use to reply. Simply press and hold the response you wish to reply with and see if you can say it! If you get 
                                it wrong, you'll be given the option to retry, or if you think you got it right, you can simply mark your answer as correct.
                                </p>
                            </div>
                            <div className="col-12 mx-auto bg-danger">
                                <div className="row mx-1">
                                    <img className="col-12 my-3 gif" src="/scriptChatAnimation.gif"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-0">
                    <h2 className="mt-4">Before you get started!</h2>
                    <p className="justify">Prior to subscribing to a language pack, you <b>MUST</b> possess at least a basic level of conversational and reading ability in the 
                    language you wish to practice. Without some conversational ability, you will find yourself struggling to understand the context of conversations, particularly 
                    in Free Chat. Reading ability is essential for Script Chat where your responses will be provided to you in your target language. Linguallect is NOT a teaching 
                    service, but a platform for practice! </p>
                </div>
            </div>
            <div className="col-lg-1"/>
        </div>
    )
}

export default About;