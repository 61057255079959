import React from "react";

const Footer = () => {
    return (
        <footer className="row bg-light bg-gradient d-flex justify-content-center footer m-0 mt-4">
            <div className="col-12 text-center mt-3">
                <p className="footer-text">If you would like to get in contact, please reach out via one of the social media platforms below, or via email - linguallect@gmail.com.</p>
                <div className="pb-1">
                    <a className="icons fa-brands fa-facebook mx-2"></a>
                    <a className="icons fa-brands fa-instagram mx-2"></a>
                    <a className="icons fa-brands fa-twitter mx-2"></a>
                    <a className="icons fa-brands fa-linkedin mx-2" href="https://www.linkedin.com/company/linguallect" target="_blank"></a>
                    <a className="icons fa-brands fa-youtube mx-2" href="https://www.youtube.com/@linguallect" target="_blank"></a>
                    <p className="footer-text mt-3">©Linguallect 2024.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;